import {
  AttendanceMember,
  IMatchStats,
  ISeason,
  ITeam,
  ITotals,
  ITrainingAttendance,
  IUser,
  IUserLoggedIn,
  MemberRoleEnum,
  RsvpStatusEnum,
} from "~/utils/api.interfaces.enums";
import {
  commitSession,
  getSession,
} from "~/sessions/MingleAuthSessionStorage.server";
import { useFetch } from "~/utils/useFetch";
import {
  data,
  LoaderFunctionArgs,
  Outlet,
  redirect,
  useLoaderData,
  useOutletContext,
} from "react-router";
import { ModalTeamIsArchived } from "~/components/modals/ModalTeamIsArchived";
import { usePagesContext } from "~/routes/_pages";
import { LRUCache } from "lru-cache";
import { FlashMessageSession } from "~/sessions/FlashMessageSession.server";
import { ModalBoostLevelRights } from "~/components/modals/ModalBoostLevelRights";

const cache = new LRUCache<string, any>({ max: 500, ttl: 1000 * 60 * 5 });

export type TeamData = {
  loggedInUser?: IUserLoggedIn;
  activeTeamWithStats: ITeam;
  activeTeam: ITeam;
  matches: IMatchStats[];
  trainings: ITrainingAttendance[];
  totals: ITotals;
  onlyPlayers: IUser[];
  ENVIRONMENT?: string;
  showSidebar?: boolean;
};
function sortMatchByDateAsc(a: IMatchStats, b: IMatchStats) {
  return (
    Number(new Date(a.scheduledAtUtc)) - Number(new Date(b.scheduledAtUtc))
  );
}
export function calculateTopTrainingPlayers(onlyPlayers: IUser[]): IUser[] {
  const topTrainingPlayers: IUser[] = [];
  onlyPlayers
    ?.sort(function (a, b) {
      return (
        Number(b.partcipatedTrainings?.length) -
        Number(a.partcipatedTrainings?.length)
      );
    })
    .map(function (e, i) {
      e.partcipatedTrainingsRank = i + 1;
      if (i < 3) {
        topTrainingPlayers.push(e);
      }
      return e;
    });

  return topTrainingPlayers;
}
export function calculateTopMatchesPlayers(onlyPlayers: IUser[]): IUser[] {
  const topMatchesPlayers: IUser[] = [];
  onlyPlayers
    ?.sort(function (a, b) {
      return (
        Number(b.partcipatedMatches?.length) -
        Number(a.partcipatedMatches?.length)
      );
    })
    .map(function (e, i) {
      e.partcipatedMatchesRank = i + 1;
      if (i < 3) {
        topMatchesPlayers.push(e);
      }
      return e;
    });

  return topMatchesPlayers;
}
export function calculateTopMinutesPlayedPlayers(
  onlyPlayers: IUser[]
): IUser[] {
  const topMinutesPlayedPlayers: IUser[] = [];
  onlyPlayers
    ?.sort(function (a, b) {
      return (
        Number(b.memberTotalMinutesPlayed) - Number(a.memberTotalMinutesPlayed)
      );
    })
    .map(function (e, i) {
      e.minutesPlayedRank = i + 1;
      if (i < 3) {
        topMinutesPlayedPlayers.push(e);
      }
      return e;
    });

  return topMinutesPlayedPlayers;
}

function sortSeasonsByDateAsc(a: ISeason, b: ISeason) {
  return Number(new Date(a.startsAtUtc)) - Number(new Date(b.startsAtUtc));
}
function calculateTotals(
  matches: IMatchStats[],
  goalsScoredByTeam: number[],
  assistsForTeam: number,
  awards: string[],
  onlyPlayers: IUser[]
): ITotals {
  let totals: ITotals = {
    totalGoals: 0,
    totalMinutesPlayed: 0,
    totalMatchMinutes: 0,
    totalDistanceCovered: 0,
    totalAssists: 0,
    totalAwards: 0,
    totalMatchesWon: 0,
    totalMatchesLost: 0,
    totalMatchesDraw: 0,
    totalCleanSheet: 0,
    totalScored: 0,
    totalConceded: 0,
    goalDifference: 0,
    totalViews: 0,
    totalEngagement: 0,
    averageMatchRating: "0",
  };

  totals.totalGoals =
    goalsScoredByTeam.length > 0
      ? goalsScoredByTeam?.reduce(function (a, b) {
          return a + b;
        })
      : 0;
  totals.totalAssists = assistsForTeam;

  totals.totalAwards = awards?.length;
  totals.totalViews = matches?.reduce((a, b) => a + (b?.viewsCount || 0), 0);
  totals.totalEngagement = matches?.reduce(
    (a, b) => a + (b?.totalComments + b?.totalCheers || 0),
    0
  );
  totals.averageMatchRating = (
    matches?.reduce(
      (a, b) => a + (b?.votingResults?.averageMatchRating || 0),
      0
    ) / matches?.length || 0
  ).toFixed(1);
  totals.totalScored = matches?.reduce((a, b) => a + (b?.score?.team || 0), 0);
  totals.totalConceded = matches?.reduce(
    (a, b) => a + (b?.score?.opponent || 0),
    0
  );

  totals.totalMatchesWon = matches?.reduce(
    (a, b) => a + (b?.score?.team > b?.score?.opponent ? 1 : 0),
    0
  );
  totals.totalMatchesLost = matches?.reduce(
    (a, b) => a + (b?.score?.team < b?.score?.opponent ? 1 : 0),
    0
  );
  totals.totalMatchesDraw = matches?.reduce(
    (a, b) => a + (b?.score?.team === b?.score?.opponent ? 1 : 0),
    0
  );
  totals.totalCleanSheet = matches?.reduce(
    (a, b) => a + (b?.score?.opponent === 0 ? 1 : 0),
    0
  );
  totals.totalScored = matches?.reduce((a, b) => a + (b?.score?.team || 0), 0);
  totals.totalConceded = matches?.reduce(
    (a, b) => a + (b?.score?.opponent || 0),
    0
  );
  totals.goalDifference = totals?.totalScored - totals?.totalConceded;
  totals.totalMinutesPlayed = onlyPlayers?.reduce(
    (a, b) => a + (b?.memberTotalMinutesPlayed || 0),
    0
  );
  totals.totalMatchMinutes = matches?.reduce(
    (a, b) =>
      a +
      (b?.matchSettings?.periodSettings &&
      b?.matchSettings?.periodSettings?.minutesPerPeriod > 0 &&
      b?.matchSettings?.periodSettings?.numberOfPeriods > 0 &&
      b?.isStarted
        ? b?.matchSettings?.periodSettings?.minutesPerPeriod *
          b?.matchSettings?.periodSettings?.numberOfPeriods
        : 0),
    0
  );

  totals.totalDistanceCovered = Number(
    (totals.totalMinutesPlayed * 0.09168).toFixed(2)
  );
  return totals;
}
function sortTrainingByDateAsc(a: ITrainingAttendance, b: ITrainingAttendance) {
  return Number(new Date(a.startAtUtc)) - Number(new Date(b.startAtUtc));
}

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const session = await getSession(request.headers.get("Cookie"));
  const flashSession = await FlashMessageSession(request);
  const params = new URL(request.url).searchParams;
  const historyAmount: string | null | number = params.get("historyAmount")
    ? params.get("historyAmount")
    : 365;

  const timespan: string | null | number = params.get("timespan")
    ? params.get("timespan")
    : "";

  const oneYearahead = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  );

  let FromUtc = encodeURI(
    new Date(
      new Date().setDate(
        new Date().getDate() - Number(timespan === "future" ? 0 : historyAmount)
      )
    ).toUTCString()
  );
  let ToUtc = encodeURI(
    new Date(timespan === "future" ? oneYearahead : new Date()).toUTCString()
  );

  if (
    flashSession?.getFlashMessage()?._action ||
    cache.get("activeTeamIdFromCache") !== session.get("teamId")
  ) {
    cache.clear();
    console.log("TEAM DATA cache cleared");
  }

  if (cache.has("teamData" + timespan + historyAmount)) {
    console.log("TEAM DATA from cache");
    return data<TeamData>(cache.get("teamData" + timespan + historyAmount), {
      headers: [["Set-Cookie", await commitSession(session)]],
    });
  }
  let teams: ITeam[] = await useFetch({
    request,
    context,
    url: "/Team/details/",
    method: "GET",
  });

  teams = teams?.sort((a, b) => b.isArchived - a.isArchived).reverse();

  const teamId =
    new URL(request.url).searchParams.get("teamId") ||
    session.get("teamId") ||
    teams[0]?.id;
  //check if teamID is an active team of the user
  let checkedActiveTeamId: string | undefined = teams?.find(
    (team) => team?.id === teamId
  )?.id;

  let activeTeam: ITeam = {} as ITeam;
  if (checkedActiveTeamId) {
    try {
      activeTeam = await useFetch({
        request,
        context,
        url: `/Team/${checkedActiveTeamId}`,
        method: "GET",
      });

      session.set("teamId", checkedActiveTeamId);
    } catch (error) {
      session.set("teamId", checkedActiveTeamId);
      return redirect("/team-select/", {
        headers: [["Set-Cookie", await commitSession(session)]],
      });
    }
  }

  if (activeTeam) {
    // console.log(historyAmount);
    // console.log(timespan);
    // console.log("from = " + decodeURI(FromUtc));
    // console.log("to = " + decodeURI(ToUtc));
    activeTeam?.league?.seasons?.sort(sortSeasonsByDateAsc);
    const matches: IMatchStats[] = await useFetch({
      request,
      context,
      url: `/Team/matches?TeamId=${activeTeam?.id}&FromUtc=${FromUtc}&ToUtc=${ToUtc}`,
      method: "GET",
    });

    const trainings: ITrainingAttendance[] = await useFetch({
      request,
      context,
      url: `/Team/trainings?TeamId=${activeTeam?.id}&FromUtc=${FromUtc}&ToUtc=${ToUtc}`,
      method: "GET",
    });

    activeTeam?.members?.map((member: IUser) => {
      member.partcipatedMatches = [];
      member.partcipatedTrainings = [];
      member.memberTotalMinutesPlayed = 0;
      member.memberTotalDistanceCovered = 0;
      member.awards = [];
    });

    if (matches?.length) matches?.sort(sortMatchByDateAsc);
    if (trainings?.length) trainings?.sort(sortTrainingByDateAsc);

    const onlyPlayers = activeTeam?.members
      ?.filter((member: IUser) => member?.role === MemberRoleEnum.Player)
      .map((member: IUser) => {
        member.scoredGoals = 0;
        member.assistedGoals = 0;
        member.warningCards = 0;
        member.exitCards = 0;
        return member;
      });
    let goalsScoredByTeam = [];
    let assistsForTeam = 0;
    let awards = [];

    matches?.map((match: IMatchStats) => {
      match.attendedPlayers = [];
      match.members.map((matchMember: IUser) => {
        onlyPlayers.map((member: IUser) => {
          if (matchMember?.id === member?.id) {
            member.scoredGoals += Number(matchMember?.scoredGoals) || 0;
            member.assistedGoals += Number(matchMember?.assistedGoals) || 0;
            member.warningCards += Number(matchMember?.warningCards) || 0;
            member.exitCards += Number(matchMember?.exitCards) || 0;
            assistsForTeam += Number(matchMember?.assistedGoals) || 0;
            if (matchMember.rsvpStatus === RsvpStatusEnum.Going) {
              member.partcipatedMatches?.push(match?.id);
              match.attendedPlayers?.push(member);

              member.distanceCovered = Number(
                ((matchMember.minutesPlayed || 0) * 0.09168).toFixed(2)
              );
              member.memberTotalMinutesPlayed += matchMember.minutesPlayed || 0;
              member.memberTotalDistanceCovered = Number(
                (member.memberTotalMinutesPlayed * 0.09168).toFixed(2)
              );
            }
            return member;
          }
        });
      });

      onlyPlayers.map((member: IUser) => {
        if (match.votingResults?.creativeWinner === member.id) {
          member?.awards?.push("creativeWinner");
          awards.push(match.votingResults?.creativeWinner);
        }
        if (match.votingResults?.effortWinner === member.id) {
          member?.awards?.push("effortWinner");
          awards.push(match.votingResults?.effortWinner);
        }
        if (match.votingResults?.mvpWinner === member.id) {
          awards.push(match.votingResults?.mvpWinner);
          member?.awards?.push("mvpWinner");
        }
      });
      //
      // match.goals.map((goal: IGoal) => {
      //   onlyPlayers.map((member: IUser) => {
      //     if (goal.scorer === member?.id) {
      //       member.goalsScoredByTeam?.push(goal?.id);
      //     }
      //
      //     if (goal.assistant === member?.id) {
      //       member.assists?.push(goal?.id);
      //       assists.push(goal.assistant);
      //     }
      //   });
      // });

      goalsScoredByTeam.push(match.score.team);
      match.attendedPlayersCount = match.attendedPlayers?.length;

      match.attendedPercentage =
        Math.ceil((match.attendedPlayersCount / onlyPlayers?.length) * 100) ||
        0;
    });

    onlyPlayers
      ?.sort(function (a, b) {
        return Number(b.scoredGoals) - Number(a.scoredGoals);
      })
      .map(function (e, i) {
        e.goalsScoredRank = i + 1;
        return e;
      });
    onlyPlayers
      ?.sort(function (a, b) {
        return Number(b.assistedGoals) - Number(a.assistedGoals);
      })
      .map(function (e, i) {
        e.assistsRank = i + 1;
        return e;
      });
    onlyPlayers
      ?.sort(function (a, b) {
        return Number(b.awards?.length) - Number(a.awards?.length);
      })
      .map(function (e, i) {
        e.awardsRank = i + 1;
        return e;
      });

    onlyPlayers
      ?.sort(function (a, b) {
        return (
          Number(b.memberTotalMinutesPlayed) -
          Number(a.memberTotalMinutesPlayed)
        );
      })
      .map(function (e, i) {
        e.minutesPlayedRank = i + 1;
        return e;
      });

    trainings?.map((training: ITrainingAttendance, index) => {
      training.members.map((user: AttendanceMember) => {
        activeTeam?.members.map((member: IUser) => {
          if (
            user?.rsvpStatus === "Going" &&
            user?.id === member?.id &&
            user?.role === MemberRoleEnum.Player
          ) {
            member.partcipatedTrainings?.push(training?.id);
          }
        });
      });

      const attendedMembers = training.members?.filter(
        (member: AttendanceMember) => {
          if (
            member.rsvpStatus === "Going" &&
            member.role === MemberRoleEnum.Player
          )
            return member;
        }
      );
      const totalTrainingMembers = training.members?.filter(
        (e: AttendanceMember) => {
          if (e.role === MemberRoleEnum.Player) return e;
        }
      );

      training.attendedMembers = attendedMembers;
      training.attendedMembersCount = attendedMembers?.length;
      training.attendedPercentage =
        Math.ceil(
          (training.attendedMembersCount / onlyPlayers?.length) * 100
        ) || 0;
    });

    matches?.sort(sortMatchByDateAsc);
    trainings?.sort(sortTrainingByDateAsc);

    const totals: ITotals = calculateTotals(
      matches,
      goalsScoredByTeam,
      assistsForTeam,
      awards,
      onlyPlayers
    );
    cache.set("activeTeamIdFromCache", activeTeam?.id);
    cache.set("teamData" + timespan + historyAmount, {
      activeTeam,
      activeTeamWithStats: activeTeam,
      matches,
      totals,
      trainings,
      onlyPlayers,
      ENVIRONMENT: context?.cloudflare?.env?.ENVIRONMENT,
    });

    return data<TeamData>(
      {
        activeTeam,
        activeTeamWithStats: activeTeam,
        matches,
        totals,
        trainings,
        onlyPlayers,
        ENVIRONMENT: context?.cloudflare?.env?.ENVIRONMENT,
      },
      {
        headers: [
          // ["Cache-Control", " max-age=36000, s-maxage=0"],
          ["Set-Cookie", await commitSession(session)],
        ],
      }
    );
  }
  return null;
};

// export const shouldRevalidate = () => false;

export default function TeamIndex() {
  const { activeTeam, loggedInUser, showSidebar } = usePagesContext();
  const {
    activeTeamWithStats,
    matches,
    totals,
    trainings,
    onlyPlayers,
    ENVIRONMENT,
  } = useLoaderData() as TeamData;

  return (
    <div>
      <ModalBoostLevelRights />
      <ModalTeamIsArchived />
      <Outlet
        context={{
          loggedInUser,
          activeTeam,
          activeTeamWithStats,
          matches,
          totals,
          trainings,
          onlyPlayers,
          ENVIRONMENT,
          showSidebar,
        }}
      />
    </div>
  );
}
export function useTeamContext() {
  return useOutletContext<TeamData>();
}
